{
  "lang_markread": "Marca com llegit",
  "lang_filter": "Filtre",
  "lang_newest": "Nous",
  "lang_unread": "Per llegir",
  "lang_starred": "Preferits",
  "lang_online_count": "Elements disponibles al servidor",
  "lang_offline_count": "Elements disponibles localment",
  "lang_tags": "Etiquetes",
  "lang_alltags": "Totes les etiquetes",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "fa {0,plural,one {1 hora},other {# hores}}",
  "lang_minutes": "fa {0,plural,one {1 minut},other {# minuts}}",
  "lang_seconds": "fa {0,plural,one {1 segon},other {# segons}}",
  "lang_days": "{0,plural,zero {avui},one {ahir},other {fa # dies}}",
  "lang_star": "Preferit",
  "lang_unstar": "Ja no ho prefereixo",
  "lang_mark": "Marca com llegit",
  "lang_unmark": "Marca com no llegit",
  "lang_load_img": "Mostra les imatges",
  "lang_open_window": "Obre",
  "lang_next": "Següent",
  "lang_searchbutton": "Cerca",
  "lang_searchremove": "Amaga la cerca",
  "lang_refreshbutton": "Actualitza les fonts",
  "lang_settingsbutton": "Configuració",
  "lang_logoutbutton": "Finalitza la sessió",
  "lang_loginbutton": "Inicia una sessió",
  "lang_sources": "Fonts",
  "lang_source_add": "Afegeix una font",
  "lang_source_opml": "o importa des d'un fitxer opml o des del Google Reader (fins al 15 de juliol de 2013)",
  "lang_source_export": "Exporta les fonts",
  "lang_source_edit": "Edita",
  "lang_source_filter": "Filtra",
  "lang_source_delete": "Esborra",
  "lang_source_warn": "Segur que voleu esborrar la font?",
  "lang_source_new": "Nova font per desar",
  "lang_source_title": "Títol",
  "lang_source_autotitle_hint": "Deixa en blanc per a obtindre el títol",
  "lang_source_tags": "Etiquetes",
  "lang_source_pwd_placeholder": "No ha canviat",
  "lang_source_comma": "Separat per comes",
  "lang_source_select": "Seleccioneu una font",
  "lang_source_type": "Tipus",
  "lang_source_save": "Desa",
  "lang_source_cancel": "Cancel·la",
  "lang_source_saved": "Desat",
  "lang_source_last_post": "Última entrada vista",
  "lang_source_refresh": "Actualitza aquesta font",
  "lang_no_entries": "No es van trobar entrades",
  "lang_more": "Més",
  "lang_login": "Inicia una sessió",
  "lang_login_username": "Nom d'usuari",
  "lang_login_password": "Contrasenya",
  "lang_login_invalid_credentials": "Nom d'usuari o contrasenya incorrectes",
  "lang_login_offline": "Emmagatzematge fora de línia",
  "lang_no_title": "Sense títol",
  "lang_error": "S'ha produït un error",
  "lang_streamerror": "No hi ha cap element carregat. Es recarrega?",
  "lang_reload_list": "Recarrega la llista",
  "lang_sources_refreshed": "S'han actualitzat les fonts",
  "lang_app_update": "selfoss s'ha actualitzat, torneu a carregar",
  "lang_app_reload": "Recarrega",
  "lang_error_session_expired": "La sessió ha expirat",
  "lang_error_add_source": "No s'ha pogut afegir la font:",
  "lang_error_delete_source": "No s'ha pogut esborrar la font:",
  "lang_error_load_tags": "No s'han pogut carregar les etiquetes:",
  "lang_error_unknown_tag": "Etiqueta desconeguda:",
  "lang_error_unknown_source": "Id de font desconeguda:",
  "lang_error_mark_items": "No s'han pogut marcar tots els elements visibles:",
  "lang_error_mark_item": "No s'ha pogut marcar/desmarcar element:",
  "lang_error_star_item": "No s'ha pogut preferir/despreferir l'element:",
  "lang_error_logout": "No s'ha pogut finalitzar la sessió:",
  "lang_error_loading": "Error de càrrega:",
  "lang_error_loading_stats": "No s'han pogut carregar les estadístiques de navegació:",
  "lang_error_saving_color": "No s'ha pogut desar el nou color:",
  "lang_error_refreshing_source": "No s'han pogut refrescar fonts:",
  "lang_error_sync": "No s'han pogut sincronitzar els últims canvis del servidor:",
  "lang_error_offline_storage": "Error d'emmagatzematge fora de línia: {0}. Recarregar pot ajudar. Es desactiva l'ús fora de línia per ara.",
  "lang_error_invalid_subsection": "Subsecció invàlida:",
  "lang_search_label": "Terme de cerca",
  "lang_article_actions": "Accions",
  "lang_error_configuration": "No es pot carregar la configuració.",
  "lang_info_url_copied": "S'ha copiat l'URL al portaretalls.",
  "lang_share_native_label": "Comparteix",
  "lang_share_diaspora_label": "Comparteix a Diaspora",
  "lang_share_twitter_label": "Comparteix al Twitter",
  "lang_share_facebook_label": "Comparteix al Facebook",
  "lang_share_pocket_label": "Desa a Pocket",
  "lang_share_wallabag_label": "Desa a Wallabag",
  "lang_share_wordpress_label": "Comparteix a WordPress",
  "lang_share_mail_label": "Comparteix per correu electrònic",
  "lang_share_copy_label": "Copia l'enllaç al portaretalls",
  "lang_error_share_native_abort": "No es pot compartir l'element—o no hi ha destinacions de compartició, o ho heu cancel·lat.",
  "lang_error_share_native": "No es pot compartir l'element.",
  "lang_close_entry": "Tanca",
  "lang_error_unwritable_logs": "No es poden escriure els registres.",
  "lang_error_check_system_logs": "S'ha produït un error. Comproveu els registres del sistema.",
  "lang_error_check_log_file": "S'ha produït un error. Comprova el fitxer de registre “{0}”.",
  "lang_offline_navigation_unavailable": "Canviar etiquetes i fonts no és disponible en el mode fora de línia.",
  "lang_error_offline_storage_not_available": "Emmagatzematge fora de línia no disponible. Assegureu-vos que el navegador {0}suporta IndexedDB API{1}. Si utilitzeu un navegador basat en Google Chrome, que no estigueu utilitzant selfoss en mode incògnit.",
  "lang_error_edit_source": "No es pot desar els canvis a les fonts:",
  "lang_article_reading_time": "{0} min lectura",
  "lang_experimental": "Experimental",
  "lang_source_menu": "Més accions",
  "lang_source_browse": "Navega",
  "lang_sources_leaving_unsaved_prompt": "Voleu deixar la configuració amb els canvis de font no desats?",
  "lang_source_warn_cancel_dirty": "Vols descartar els canvis no desats?",
  "lang_tag_change_color_button_title": "Canvia el color",
  "lang_sources_loading": "S'estan carregant les fonts…",
  "lang_source_deleting": "S'està suprimint la font…",
  "lang_source_params_loading": "S'estan carregant els paràmetres…",
  "lang_no_sources": "No hi ha fonts configurades.",
  "lang_source_saving": "S'estan desant els canvis a la font…",
  "lang_source_go_to_settings": "Obre a la configuració",
  "lang_entries_loading": "S'estan carregant les entrades…",
  "lang_login_in_progress": "S'està intentant iniciar sessió…",
  "lang_login_error_generic": "No s'ha pogut iniciar sessió: {errorMessage}",
  "lang_error_unauthorized": "Si us plau, {link_begin}inicieu sessió{link_end} per a continuar.",
  "lang_share_mastodon_label": "Comparteix a Mastodon"
}
